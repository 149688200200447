<template>
  <v-app-bar app clipped-left clipped-right color="primary" dark>
    <!---Logo part -->
    <v-toolbar-title class="align-center d-flex toolbar-title">
      <span class="logo-icon logo-usamedic">
        <img src="@/assets/images/logo-usamedic.png" />
      </span>
      <span class="logo-text text-center" :class="`${showLogo ? '' : 'hidelogo'}`">
        <p class="ma-0 header-title">USAMEDIC</p>
      </span>
    </v-toolbar-title>
    <!---Logo part -->
    <!---/Toggle sidebar part -->
    <div @click="showhideLogo">
      <v-app-bar-nav-icon
        @click="
          $vuetify.breakpoint.smAndDown
            ? setSidebarDrawer(!Sidebar_drawer)
            : $emit('input', !value)
        "
      />
    </div>
    <!---/Toggle sidebar part -->
    <!---Search part -->
    <v-btn
      dark
      icon
      class="mr-1 d-sm-block d-none"
      @click="searchbox"
    >
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <div v-if="showSearch" class="searchinput primary">
      <template>
        <v-text-field
          v-model="searchText"
          placeholder="Search & hit enter"
          class="mt-3 mb-0"
          append-icon="mdi-close"
          :readonly="readonly"
          @click:append="searchbox"
        ></v-text-field>
      </template>
    </div>
    <!---/Search part -->
    <v-spacer />
    <!---right part -->
    <!---Notification -->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      v-if="permissions.includes('is_superuser')"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-badge color="red" :dot="alerts.length ? true: false">
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list v-if="alerts.length" style="max-height: 80vh; overflow-y: auto;">
        <h4 class="px-5 py-3 pt-2 font-weight-medium title">
          Notificaciones de pagos pendientes
        </h4>
        <v-divider></v-divider>
        <v-list-item
          v-for="(item, i) in alerts"
          :key="i"
          @click="$router.push({ name: 'UserDetail', params: { id: item.usuario.id } })"
        >
          <v-list-item-title>
            <div class="d-flex align-center py-3">
              <div class>
                <v-btn
                  class="mr-3"
                  depressed
                  fab
                  small
                  dark
                  :color="item.iconbg"
                >
                  <v-icon dark>mdi-account</v-icon>
                </v-btn>
              </div>
              <div>
                <h4 class="font-weight-medium">{{ item.usuario.email }}</h4>
                <h3
                  class="text--secondary caption descpart d-block text-truncate"
                >
                  S/. {{ item.monto }}
                </h3>
                <small class="text--secondary">{{ $options.filters.formatDate(item.fecha_alerta) }}</small>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <h4 class="text-center px-5">
          No hay pagos pendientes
        </h4>
      </v-list>
    </v-menu>
    <!---Notification -->
    <!---User -->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-avatar size="40" v-if="user">
            <img :src="user.foto || defaultUserImage" :alt="user.username" />
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(item, i) in userprofile" :key="i" @click="href">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="doLogout()">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!---User -->
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import defaultUserImage from "@/assets/images/users/default-user.png"

export default {
  name: "Header",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object
    }
  },
  data: () => ({
    searchText: '',
    defaultUserImage,
    showLogo: true,
    showSearch: false,
    readonly: false,
    notifications: [
      {
        title: "Launch Admin",
        iconbg: "error",
        icon: "mdi-link-variant",
        desc: "Just see the my new admin!",
        time: "9:30AM",
      },
      {
        title: "Event today",
        iconbg: "success",
        icon: "mdi-calendar-check",
        desc: "Just a reminder that you have event",
        time: "10:30AM",
      },
      {
        title: "Settings",
        iconbg: "info",
        icon: "mdi-cog",
        desc: "You can customize this template as you want",
        time: "11:30AM",
      },
      {
        title: "Pavan Kumar",
        iconbg: "indigo",
        icon: "mdi-account",
        desc: "Sent you an notification",
        time: "12:30AM",
      },
    ],
    messages: [
      {
        title: "Sonu Nigam",
        avatar: "1",
        avatarstatus: "success",
        desc: "Singing Show tonigh at 9pm!",
        time: "9:30AM",
      },
      {
        title: "Sonu Nigam",
        avatar: "2",
        avatarstatus: "error",
        desc: "The greate malody songs ever sung",
        time: "10:30AM",
      },
      {
        title: "Arijit singh",
        avatar: "3",
        avatarstatus: "warning",
        desc: "You can customize this template as you want",
        time: "11:30AM",
      },
      {
        title: "Pavan Kumar",
        avatar: "4",
        avatarstatus: "success",
        desc: "Sent you an notification",
        time: "12:30AM",
      },
    ],
    userprofile: [
      { title: "My Contacts" },
      { title: "My Balance" },
      { title: "Inbox" },
      { title: "Account Setting" },
    ],
    href() {
      return undefined;
    },
    paymentsAlerts: [],
    usersWithAlert: [],
    timeout: null
  }),

  computed: {
    ...mapState(["Sidebar_drawer", "searchGlobal", "alerts"]),
    ...mapGetters(['permissions']),

  },

  watch: {
    async searchText () {
      this.timeout = null
      this.timeout = setTimeout(async () => {
        await this.SET_SEARCH(this.searchText)
      }, 800)
      
    },
    '$route': {
      deep: true,
      handler (to, from) {
        if (to.name !== from.name) {
          this.SET_SEARCH('')
          this.searchText = ''
          this.showSearch = false
        }
      }
    }
  },
  methods: {
    ...mapActions(['logout', 'fetchAlerts']),
    ...mapMutations([{
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }, 'SET_SEARCH']),
    showhideLogo () {
      this.showLogo = !this.showLogo
    },
    async searchbox () {
      this.showSearch = !this.showSearch
      await this.SET_SEARCH('')
      this.searchText = ''
    },
    doLogout () {
      this.logout()
      this.$router.push({ name: 'Login' })
    }
  },
  async mounted () {
    if (this.$route.query.search && (this.$route.name === 'Users' || this.$route.name === 'Payments')) {
      this.searchText = this.$route.query.search
    } else {
      this.showSearch = false
    }
    if (this.$route.query.search) {
      this.showSearch = true
      this.searchText = this.$route.query.search
    }
    if(this.permissions.includes('is_superuser')){
      await this.fetchAlerts()
    }
  }
};
</script>

<style lang="scss">
.hidelogo {
  display: none;
}
.searchinput {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descpart {
  max-width: 220px;
}
.logo-usamedic {
  width: 40px;
  text-align: center;
}
.header-title {
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  font-weight: 600;
  font-family: Poppins;
  width: 185px;
}

.toolbar-title {
  justify-content: space-evenly;
}
</style>
