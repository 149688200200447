<template>
  <v-app id="inspire">
    <Header :user="user" v-model="expandOnHover"></Header>

    <v-main>
      <v-container fluid class="page-wrapper" :style="$route.name==='Inicio' ? 'background: #FFFFFF;': ''">

        <router-view/>
        <v-btn v-if="false" bottom color="success" dark fab fixed right @click.stop="setCustomizerDrawer(!Customizer_drawer)">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </v-container>
    </v-main>
    <Sidebar :user="user" :expand-on-hover.sync="expandOnHover"></Sidebar>

    <Customizer v-model="expandOnHover"></Customizer>

    <!--    <Footer></Footer> -->

  </v-app>

</template>

<script>
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import Customizer from './customizer/Customizer';
import {mapState, mapMutations, mapActions} from 'vuex';

export default {
  name: 'Layout',

  components: {
    Header,
    Sidebar,
    Customizer
  },

  props: {
    source: String,
  },
  data: () => ({
    expandOnHover: false
  }),
  computed: {
    ...mapState(['Customizer_drawer', 'user']),
  },
  methods: {
    ...mapActions(['fetchUser']),
    ...mapMutations({
      setCustomizerDrawer: 'SET_CUSTOMIZER_DRAWER',
    })
  },
  mounted() {
    this.fetchUser()
  }
};
</script>

<style>
</style>
