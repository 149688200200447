export default [
  {
    icon: 'mdi-view-dashboard',
    title: 'Dashboard',
    to: '/dashboard/index',
    permission: 'is_staff'
  },
  {
    icon: 'mdi-file',
    title: 'Formularios',
    to: '/dashboard/inscriptions',
    permission: 'matriculas.view_formulario'
  },
  {
    icon: 'mdi-account-file-text',
    title: 'Matrículas',
    to: '/dashboard/matriculas',
    permission: 'matriculas.view_formulario'
  },
  {
    icon: 'mdi-barcode',
    title: 'Codigos',
    to: '/dashboard/codes',
    permission: 'matriculas.view_codigoreferencia'
  },
  {
    icon: 'mdi-format-list-text',
    title: 'Secciones',
    to: '/dashboard/sections',
    permission: 'matriculas.view_seccion'

  },
  {
    icon: 'mdi-account',
    title: 'Usuarios',
    to: '/dashboard/users',
    permission: 'users.view_user'

  },
  {
    icon: 'mdi-credit-card-outline',
    title: 'Pagos',
    to: '/dashboard/payments',
    permission: 'matriculas.view_pago'

  },
  {
    icon: 'mdi-note-text',
    title: 'Notas',
    to: '/dashboard/notes',
    permission: 'notas.view_nota'

  },
  {
    icon: 'mdi-cellphone-text',
    title: 'Simulacros',
    to: '/dashboard/simulacros',
    permission: 'banqueo.view_simulacro'

  }
]

